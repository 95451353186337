<template>
  <div v-if="mode==='edit'" class="base-input">
    <label class="text-left font-label">{{ inputLabel }}</label>
    <v-textarea
      class="input-field"
      :class="inputStatus"
      :auto-grow="autoGrow"
      :append-icon="appendedIcon"
      :prepend-icon="prependedIcon"
      @click:append="appendIconCallback"
      @click:prepend="prependIconCallback"
      v-bind="$attrs"
      v-on="$listeners"
      @input="input"
    ></v-textarea>
  </div>
  <div v-else>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseTextAreaField',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    mode: {
      type: String,
      default: 'edit',
    },
    autoGrow: {
      type: Boolean,
      default() {
        return false;
      },
    },
    inputLabel: {
      type: String,
      default: null,
    },
    status: {
      type: String,
      default: null,
    },
    appendIcon: {
      type: String,
      default: null,
    },
    prependIcon: {
      type: String,
      default: null,
    },
    appendIconCallback: {
      type: Function,
      default: () => {},
    },
    prependIconCallback: {
      type: Function,
      default: () => {},
    },
    onInput: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    appendedIcon() {
      if (this.appendIcon) {
        return this.appendIcon;
      }
      if (this.status === 'error') {
        return 'mdi-alert-circle-outline';
      }
      return null;
    },
    prependedIcon() {
      if (this.prependIcon) {
        return this.prependIcon;
      }
      return null;
    },
    inputStatus() {
      return `${this.status}State`;
    },
  },
  methods: {
    input(e) {
      this.onInput(e);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-text-field--filled:not(.v-input--is-focused):not(.v-input--has-state) {
  .v-input__control > .v-input__slot:hover {
    background-color: transparent;
  }
}
  ::v-deep .v-input input {
    max-height: 36px !important;
  }
  ::v-deep .errorState.v-text-field--solo .v-input__control > .v-input__slot {
    border: solid 1px #ff0808 !important;
  }
  ::v-deep .successState.v-text-field--solo .v-input__control > .v-input__slot {
    border: solid 1px #00c48c !important;
  }
  ::v-deep .disabledState.v-text-field--solo .v-input__control > .v-input__slot {
    border: solid 1px #e6e8ed !important;
    background-color: #ecebed !important;
  }
  ::v-deep .normalState.v-text-field--solo .v-input__control > .v-input__slot {
    border: solid 1px #e6e8ed !important;
  }
  ::v-deep ::placeholder { /* FireFox */
    font-family: $fontFamily1;
  }
  ::v-deep :-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: $fontFamily1;
  }
  ::v-deep ::-ms-input-placeholder { /* Microsoft Edge */
    font-family: $fontFamily1;
  }
</style>
